<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="greetings"
          :search="search"
          title="Sambutan"
          subtitle="Halaman untuk menambahkan sambutan"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @show-image="showImage"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || greeting.thumbnail === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Sambutan': 'Edit Sambutan'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-3"
          >
            <div>
              <FileInput
                v-model="initialFile"
                :value="greeting.thumbnail"
                label="Gambar"
                outlined
                dense
                :disabled="false"
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="greeting.title"
                label="Judul"
                outlined
                dense
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="greeting.content"
                label="isi Sambutan"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Sambutan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="greeting.thumbnail"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import FileInput from '../../components/FileInput.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'Greeting',
  components: {
    DataTablePagination,
    ModalDialog,
    ImageOverlay,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      width: 600,
      school: [],
      school_uuid: null,
      page: 1,
      imageOverlay: false,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'greeting',
      greetings: [],
      greeting: {
        thumbnail: null,
        title: '',
        content: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Judul', value: 'title' },
        { text: 'Isi Sambutan', value: 'content' },
        { text: 'Thumbnail', value: 'thumbnail' },
        { text: 'Aksi', value: 'actions' },
      ],
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.greeting.thumbnail === 'string') {
          return new File([this.greeting.thumbnail], this.greeting.thumbnail, { type: 'text/plain' })
        }

        return this.greeting.thumbnail
      },
      set() {
        return this.greeting.thumbnail
      },
    },
  },
  watch: {
    greeting: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content']
        Object.entries(this.greeting).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listGreeting(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listGreeting()
  },
  methods: {
    resetForm() {
      this.greeting.thumbnail = null
      this.greeting.title = ''
      this.greeting.content = ''
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.greeting = data.data
      })
      this.modalDialog = true
    },
    getFile(data) {
      this.greeting.thumbnail = data
    },
    async listGreeting(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        school_uuid: this.school_uuid,
      }).then(({ data }) => {
        this.greetings = data.data.map((greeting, index) => ({
          ...greeting,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('title', this.greeting.title)
      this.formData.append('content', this.greeting.content)
      this.formData.append('thumbnail', this.greeting.thumbnail)
      // this.formData.append('school_uuid', this.school_uuid)
      if (typeof this.greeting.thumbnail === 'string') {
        this.formData.delete('thumbnail')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.greeting.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listGreeting(this.filterQuery)
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('title', this.greeting.title)
      this.formData.append('content', this.greeting.content)
      this.formData.append('thumbnail', this.greeting.thumbnail)
      this.formData.append('school_uuid', this.school_uuid)

      if (this.greeting.thumbnail === null) {
        this.showSnackbar({
          text: 'please add thumbnail first !!',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listGreeting(this.filterQuery)
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.greeting.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.greeting.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listGreeting(this.filterQuery)
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listGreeting(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get('greeting', uuid).then(
        ({ data }) => {
          this.greeting = data.data
          this.greeting.thumbnail = data.data.thumbnail
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
  },
}
</script>

<style>
</style>
